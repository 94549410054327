import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import configureAmplify from '../configureAmplify';
import { Auth, Hub } from 'aws-amplify';
import { getApiDomain } from '../utils/apiHelper';
import { CognitoUser } from '@aws-amplify/auth';
import {
  PROD_DOMAIN,
  PROD_VIAS_USER_AUTHORIZATION_KEY_NAME,
  DEV_VIAS_USER_AUTHORIZATION_KEY_NAME,
} from '../utils/constants';
/*
This file contains context on the user that is currently logged in.  It is 
intended to be used for handling user login information all in one place across
the app.

It is more or less follows the approach of this article: 
https://devtrium.com/posts/how-use-react-context-pro

And it will allow a similar approach to using protected routes as outlined in
this amplify doc:
https://ui.docs.amplify.aws/react/guides/auth-protected
*/

/*
IUserContext contains gandalfUserData and cchAuthorization, where 
gandalfUserData is the claims received from Gandalf and cchAuthorization 
contains a user's cch authorization.  It also contains setUserContext which can
be used to update user context from child components. gandalfUserData and 
cchAuthorization are separated so that cchAuthorization can be obtained through
VIAS inline attributes or an API call.  Logic for this will live in this file.
*/
type IUserContext = {
  isLoading: boolean; // to identify if user info is loading in child components
  gandalfUserData: CognitoUser | any;
  cchAuthorization: string | null; // null if not signed in.  "none", "learner_admin", "tops" otherwise
  setUserContext: Dispatch<SetStateAction<IUserContext>>;
};

/*
when a user is logged out, gandalfUserData and cchAuthorization are expected to
be null.
*/
const initialContext: IUserContext = {
  isLoading: true,
  gandalfUserData: null,
  cchAuthorization: null,
  setUserContext: (): void => {
    throw new Error('setUser function must be overridden');
  },
};

const loggedOutContextVariables: any = {
  isLoading: false,
  gandalfUserData: null,
  cchAuthorization: null,
};

// create context
const UserContext = createContext<IUserContext>(initialContext);

const getUserAttr = (user: any, attr: string) =>
  user?.signInUserSession?.idToken?.payload?.[attr];

const getInlineAttributeNameForStage = () => {
  if (window.location.hostname.toLowerCase() === PROD_DOMAIN) {
    return PROD_VIAS_USER_AUTHORIZATION_KEY_NAME;
  }
  return DEV_VIAS_USER_AUTHORIZATION_KEY_NAME;
};

const getInlineAuthorization = (gandalfUserData: any) => {
  // check for VIAS inline attribute authorization
  const inlineAuthorizationAttributeName = getInlineAttributeNameForStage();
  let inlineAuthorization = getUserAttr(
    gandalfUserData,
    inlineAuthorizationAttributeName
  );
  return inlineAuthorization;
};

const getUserDetailsAuthorization = async (gandalfUserData: any) => {
  // get authorization from /userDetails
  const apiDomain = getApiDomain();
  const jwtToken = gandalfUserData?.signInUserSession.idToken.jwtToken;

  // TO DO: add retries
  const response = await fetch(`${apiDomain}/v1/userDetails`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const json_response: any = await response.json();

  return json_response['AccountType'];
};

const getUserAuthorization = async (gandalfUserData: IUserContext) => {
  // user not signed in
  if (gandalfUserData === null) {
    return null;
  }

  // get VIAS inline attribute authorization
  let inlineAuthorization = getInlineAuthorization(gandalfUserData);
  if (inlineAuthorization !== undefined) {
    return inlineAuthorization;
  }

  // if user doesn't have inlineAuthorization, get it from /userDetails
  let userDetailsAuthorization = await getUserDetailsAuthorization(
    gandalfUserData
  );
  return userDetailsAuthorization;
};

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  // the value that will be given to the context
  const [userContext, setUserContext] = useState(initialContext);
  const value = { ...userContext, setUserContext };

  // Configure amplify in the App so that it does not create effects in boundaries before content load
  // Consider creating a seperate context for Amplify to better control this behavior.
  configureAmplify();

  /*
  Check if user is signed in, and signal to react DOM that userContext
  has finished loading if no users signed in.  This is inside a useEffect
  because we want this to run after after rendering component, so the rest
  of the DOM tree can know that userContext.isLoading is false, if no user
  is signed in.
  */
  const setUserContextIfAvailable = () => {
    Auth.currentAuthenticatedUser()
      .then(async (userData) => {
        let authorization = await getUserAuthorization(userData);
        setUserContext({
          ...userContext,
          isLoading: false,
          gandalfUserData: userData,
          cchAuthorization: authorization,
        });
      })
      .catch(() => {
        // console.log('Not signed in');
        setUserContext({
          ...userContext,
          ...loggedOutContextVariables,
        });
        return null;
      });
  };

  // Setup Amplify Hub listener to receive auth events.
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        setUserContextIfAvailable();
        break;
      case 'signOut':
        setUserContext({
          ...userContext,
          ...loggedOutContextVariables,
        });
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        console.debug('Sign in failure', data);
        break;
    }
  });

  useEffect(() => {
    setUserContextIfAvailable();
    // eslint-disable-next-line
  }, []);

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
